import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import tola from '../Images/tola.webp';
import profile from '../Images/profile.webp';
import facebookHero from '../Images/facebook-hero.webp';
import { Image } from 'react-bootstrap';
import { TikTokEmbed, InstagramEmbed, FacebookEmbed } from 'react-social-media-embed';

function ControlledCarousel() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <Carousel className='carousel-hero' indicators={false} activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item>
                <Image
                    roundedCircle
                    className="img-fluid d-block img-thumbnail hero-img"
                    src={tola}
                    alt="Tola Smith"
                />
                <Carousel.Caption>
                    <h3>@tolasmith</h3>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <TikTokEmbed url='https://www.tiktok.com/@tolasmith/video/7211973297435610414?is_from_webapp=1&sender_device=pc&web_id=7166403965768205866' />
            </Carousel.Item>
            <Carousel.Item>
            <a href='https://instagram.com/tolasmith?igshid=YmMyMTA2M2Y='><Image
                    rounded={true}
                    className="img-fluid d-block img-thumbnail hero-img"
                    src={profile}
                    alt="Tola Smith"
                /></a>
                <Carousel.Caption>
                    <h3>👆👆👆</h3>
                    <h3>Instragram Profile Link</h3>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
            <a href='https://www.facebook.com/tolalafe?mibextid=LQQJ4d'><Image
                    rounded={true}
                    className="img-fluid d-block img-thumbnail hero-img"
                    src={facebookHero}
                    alt="Tola Smith"
                /></a>
                <Carousel.Caption>
                <h3>👆👆👆</h3>
                <h3>Facebook Profile Link</h3>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
}

// render(<ControlledCarousel />);
export default ControlledCarousel;