import logo from './Images/logo.png';
import amazon from './Images/amazon.png';
import instagram from './Images/instagram.png';
import tiktok from './Images/tiktok.png';
import facebook from './Images/facebook.png';
import ltk from './Images/ltk.png';

import wig40 from './Images/40wig.webp';

import { Container, Nav, Navbar, Image, Card, Button, Row, Col } from 'react-bootstrap';
import ControlledCarousel from './Component/Carousel';

import './App.css';

function App() {

  return (
    <>
      <Navbar className='App-Nav' fixed='top' expand={true}>
        <Container>
          <Navbar.Brand>
            <Image className='img-fluid' width={48} alt='Tola Smith' src={logo} />
          </Navbar.Brand>
          <Nav className='m-auto'>
            <Nav.Link href='http://www.amazon.com/shop/Tolasmith'><Image className='img-fluid social-link' width={48} alt='Amazon Storefront' src={amazon} /></Nav.Link>
            <Nav.Link href="https://www.tiktok.com/@tolasmith"><Image className='img-fluid social-link' width={48} alt='TikTok' src={tiktok} /></Nav.Link>
            <Nav.Link href="https://instagram.com/tolasmith?igshid=YmMyMTA2M2Y="><Image className='img-fluid social-link' width={48} alt='Instagram' src={instagram} /></Nav.Link>
            <Nav.Link href="https://www.facebook.com/tolalafe?mibextid=LQQJ4d"><Image className='img-fluid social-link' width={48} alt='Facebook' src={facebook} /></Nav.Link>
            <Nav.Link href="https://www.shopltk.com/explore/Tolasmith"><Image className='img-fluid social-link' width={54} alt='Like To Know' src={ltk} /></Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <Container className='App'>
        <ControlledCarousel />
        <hr />

        <h1>Featured Products</h1>

        <Row xs={2} md={4} className='g-4'>
          <Col>
          <Card style={{
            width: "25vh"
          }}>
          <Card.Img className='img-fluid' variant="top" src={wig40} />
          <Card.Body>
            <Card.Text>40 inch Yuan Boutique Deep Wave Wig</Card.Text>
            <Button className='btn-link' href="https://a.aliexpress.com/_mtb5aBS">Click here</Button>
          </Card.Body>
        </Card>  
          </Col>


          {/* <Col>
          <Card style={{
            width: "25vh"
          }}>
          <Card.Img className='img-fluid' variant="top" src={wig40} />
          <Card.Body>
            <Card.Text>40 inch Yuan Boutique Deep Wave Wig</Card.Text>
            <Button className='btn-link' href="https://a.aliexpress.com/_mtb5aBS">Click here</Button>
          </Card.Body>
        </Card>  
          </Col> */}

        </Row>
        
      </Container>
    </>
  )
}

export default App;
